<template>
  <div style=" margin: -15px -15px;">
    <a-spin :spinning="spinning">
    <div class="live-top">
      <div class="live-top-input" style="width: 30%">
        <div>状态:</div>
        <a-radio-group
            style="margin-left: 5px"
            v-model="radioValue"
            @change="radioChange">
          <a-radio-button value="">
            全部
          </a-radio-button>
          <a-radio-button value="1">
            已发布
          </a-radio-button>
          <a-radio-button value="0">
            未发布
          </a-radio-button>
        </a-radio-group>
      </div>
      <div class="live-top-input">
        <div>标题:</div>
        <a-input
            v-model="titleData"
            class="input"
            placeholder="请输入标题" />
      </div>
      <div class="live-top-input">
        <div>所属平台:</div>
        <a-select
            @change="domainCodeChange"
            class="input"
            v-model="domainCode"
            placeholder="请选择所属平台">
          <a-select-option
              v-for="select_S in yun_subordinate_select"
              :value="select_S.domain_code">
            {{select_S.name}}
          </a-select-option>
        </a-select>
      </div>
      <div class="live-top-input">
        <div>年份:</div>
        <a-date-picker
            v-model="year"
            mode="year"
            v-decorator="['year']"
            placeholder="请输入年份"
            format="YYYY"
            class="input"
            :open="yearShowOne"
            @openChange="openChangeOne"
            @panelChange="panelChangeOne"/>
      </div>
      <div class="live-top-input">
        <a-button
            @click="seachBtn"
            type="primary">查询</a-button>
        <a-button
            @click="resetBtn"
            style="margin-left: 10px">重置</a-button>
      </div>
    </div>
      <div style="margin: 10px">
        <a-button @click="addTopBtn" type="primary" style="width: 100px">新增</a-button>
      </div>
    <a-table
        style="width: 98%;margin: auto"
        :rowKey='record=>record.id'
        :pagination="false"
        :columns="columns"
        :data-source="dataList">
      <span slot="functionSet" slot-scope="text, record">
        <a @click="functionSet(record)">功能设置</a>
      </span>
      <span slot="system" slot-scope="text, record">
        <a @click="restBtn(record)">编辑</a>
        <a @click="promotion_Btn(record.id)"
            style="margin-left: 8px" >推广</a>
<!--        <a @click="upBtn(record.id)"-->
<!--            style="margin-left: 5px">上移</a>-->
<!--        <a-->
<!--            @click="downBtn(record.id)"-->
<!--            style="margin-left: 5px">下移</a>-->
      </span>
      <span slot="isPublished" slot-scope="text, record">
        <a-switch
            v-model="record.isPublished === 1 ? true:false"
            checked-children="是"
            un-checked-children="否"
            @change="switchBtn(record.id,record.isPublished === 1?0:1)"
            default-checked />
      </span>
    </a-table>
    <div style="width: 100%">
      <div style="float: right;margin-right: 20px;margin-top: 15px;margin-bottom: 15px">
        <a-pagination
            show-quick-jumper
            :current="page_no"
            :total="total"
            @change="paginationChange" />
      </div>
    </div>

<!--------------------------------------对话框------------------------>
    <a-modal
          @cancel="()=>{this.add_visible = false}"
          width="600px"
          v-model="add_visible"
          title="新增">
        <a-form-model
            ref="content_list_Form"
            :model="add_Form"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
        >
          <a-form-model-item prop="beginImageDetail" label="封面图">
            <div style="display: flex;width: 450px;">
              <div class="uploadImg" >
                <input
                    style="width: 100px;height: 100px;opacity: 0;"
                    accept="image/*"
                    type="file"
                    @change="add_upPosterCover"
                    ref="add_upPosterCover_ref" />
              </div>
              <div
                  class="upload_poster">
                <img
                    v-if="add_Form.coverPics[0]"
                    alt=""
                    style="width: 100%;height:100%"
                    :src="add_Form.coverPics[0]"
                />
              </div>
            </div>
            <div style="color: #a1a1a1">注:尺寸宽640*360，大小不超过500KB</div>
          </a-form-model-item>

          <a-form-model-item  label="会议名称">
            <a-input v-model="add_Form.name" placeholder="请输入会议名称"/>
          </a-form-model-item>

          <a-form-model-item  label="会议日期">
            <a-range-picker @change="DateChange"/>
          </a-form-model-item>

          <a-form-model-item  label="会议地址">
            <a-input v-model="add_Form.address" placeholder="请输入会议地址"/>
          </a-form-model-item>

          <a-form-model-item  label=" 直播开始时间">
            <a-date-picker
                @change="StartChange"
                format="YYYY-MM-DD HH:mm:ss"
                :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
            />
          </a-form-model-item>

          <a-form-model-item  label="所属平台">
            <a-select
                class="input"
                v-model="add_Form.platform"
                placeholder="请选择所属平台">
              <a-select-option
                  @click="selectMode(platforms.value)"
                  v-if="platforms.name !== '全部'"
                  v-for="platforms in yun_subordinate_select"
                  :value="platforms.domain_code">
                {{platforms.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item  label="选择模版">
            <a-select
                @change="templateChange"
                class="input"
                v-model="add_Form.template"
                placeholder="请选择模版">
              <a-select-option
                  @click="selectTem(templates.id)"
                  v-if="templates.name !== '全部'"
                  v-for="templates in template_list"
                  :value="templates.id">
                {{templates.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item prop="beginImageDetail" label="背景图">
            <div style="display: flex;width: 450px;">
              <div class="uploadImg" >
                <input
                    style="width: 100px;height: 100px;opacity: 0;"
                    accept="image/*"
                    type="file"
                    @change="add_back"
                    ref="add_back_ref" />
              </div>
              <div
                  class="upload_poster">
                <img
                    v-if="add_Form.voverImg"
                    alt=""
                    style="width: 100%;height:100%"
                    :src="add_Form.voverImg"
                />
              </div>
            </div>
            <div style="color: #a1a1a1">注:尺寸宽640，大小不超过1M</div>
          </a-form-model-item>

          <a-form-model-item label="主色值" >
            <a-input v-model="add_Form.mainColor">
              <input
                  :value="add_Form.mainColor"
                  type="color"
                  slot="addonBefore"
                  @change="colorAdd"
                  style="outline-style: none ;border: 0; padding: 0;height: 29px">
            </a-input>
          </a-form-model-item>
          <div
              :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
          >
            <a-button  @click="()=>{this.add_visible = false}">
              取消
            </a-button>
            <a-button style="margin-left: 10px" type="primary" @click="add_content_list_Ok">
              确认
            </a-button>
          </div>
        </a-form-model>
      </a-modal>

    <a-drawer
        title="推广"
        :width="600"
        :visible="promotion_visible"
        :body-style="{ paddingBottom: '80px' }"
        @close="()=>{this.promotion_visible = false;
          this.promotion_form.isBeginImage = null;
          this.promotion_form.beginImageDetail = [];
          this.promotion_form.beginImageTime = '';
          this.promotion_form.isBannerShow = 0;
          this.promotion_form.bannerImage = '';
        }"
    >
      <a-form-model
          ref="promotion_ruleForm_form_two"
          :model="promotion_form"
          :rules="promotion_rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="开场图">
          <a-switch
              :checked="promotion_form.isBeginImage === 1?true:false"
              checked-children="开"
              un-checked-children="关"
              @change="promotion_Switch_btn(promotion_form.isBeginImage === 0?1:0)"
              default-checked />
        </a-form-model-item>
        <a-form-model-item prop="beginImageDetail" label="选择图片">
          <div style="display: flex;width: 450px;">
            <div class="uploadImg" >
              <input
                  style="width: 100px;height: 100px;opacity: 0;"
                  accept="image/*"
                  type="file"
                  @change="promotion_upPosterCover"
                  ref="promotion_descImageUrl" />
            </div>
            <div
                class="upload_poster">
              <img
                  v-if="promotion_form.beginImageDetail[0]"
                  alt=""
                  style="width: 100%;height:100%"
                  :src="promotion_form.beginImageDetail[0]"
              />
            </div>
          </div>
          <div style="color: #a1a1a1">注:尺寸宽640，大小不超过1M</div>
        </a-form-model-item>
        <a-form-model-item label="时长" prop="beginImageTime">
          <a-input v-model.number="promotion_form.beginImageTime" placeholder="建议不超过5秒"/>
        </a-form-model-item>
        <a-form-model-item label="APP 首页广告">
          <a-switch
              :checked="promotion_form.isBannerShow === 1?true:false"
              checked-children="开"
              un-checked-children="关"
              @change="openTwo_Switch_btn(promotion_form.isBannerShow === 0?1:0)"
              default-checked />
        </a-form-model-item>
<!--        <a-form-model-item label="APP直播界面">-->
<!--          <a-switch-->
<!--              :checked="promotion_form.openThree === 1?true:false"-->
<!--              checked-children="开"-->
<!--              un-checked-children="关"-->
<!--              default-checked />-->
<!--        </a-form-model-item>-->
        <a-form-model-item label="广告图" prop="bannerImage">
          <div style="display: flex;width: 450px;">
            <div class="uploadImg" >
              <input
                  style="width: 100px;height: 100px;opacity: 0;"
                  accept="image/*"
                  type="file"
                  @change="promotion_upPosterCover_beginImage"
                  ref="promotion_descImageUrl_beginImage_two" />
            </div>
            <div
                class="upload_poster_beginImage">
              <img
                  v-if="promotion_form.bannerImage"
                  alt=""
                  style="width: 100%;height:100%"
                  :src="promotion_form.bannerImage"
              />
            </div>
          </div>
          <div style="color: #a1a1a1">注:尺寸 1920*520，大小不超过1M</div>
        </a-form-model-item>
      </a-form-model>

      <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click="()=>{
          this.promotion_visible = false;
          this.promotion_form.isBeginImage = null;
          this.promotion_form.beginImageDetail = [];
          this.promotion_form.beginImageTime = '';
           this.promotion_form.isBannerShow = 0;
          this.promotion_form.bannerImage = '';
        }">
          取消
        </a-button>
        <a-button type="primary" @click="promotion_Submit">
          确认
        </a-button>
      </div>
    </a-drawer>

    <a-modal
        @cancel="()=>{this.$refs.content_list_Form.resetFields();this.list_visible = true;this.getLiveList(this.page_no, this.page_size, this.radioValue, this.titleData,this.year,this.domainCode)}"
        width="600px"
        v-model="list_visible"
        title="编辑">
      <a-form-model
          ref="content_list_Form"
          :model="list_Form"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
      >
        <a-form-model-item  label="会议名称">
          <a-input v-model="list_Form.title"/>
        </a-form-model-item>
        <a-form-model-item  label="会议地址">
          <a-input v-model="list_Form.address"/>
        </a-form-model-item>
        <a-form-model-item label="会议日期">
          <a-range-picker
              @change="picher_onChange"
              :allowClear="false"
              :value="[moment(list_Form.beginDate, 'YYYY-MM-DD'), moment(list_Form.endDate, 'YYYY-MM-DD')]"
              :format="'YYYY-MM-DD'"/>
        </a-form-model-item>
        <a-form-model-item label="主色值" >
          <a-input v-model="list_Form.mainColor">
            <input
                :value="list_Form.mainColor"
                type="color"
                slot="addonBefore"
                @change="colorRest"
                style="outline-style: none ;border: 0; padding: 0;height: 29px">
          </a-input>
        </a-form-model-item>
        <a-form-model-item prop="beginImageDetail" label="封面图">
          <div style="display: flex;width: 450px;">
            <div class="uploadImg" >
              <input
                  style="width: 100px;height: 100px;opacity: 0;"
                  accept="image/*"
                  type="file"
                  @change="list_Form_upPosterCover"
                  ref="list_Form_upPosterCover_ref" />
            </div>
            <div
                class="upload_poster">
              <img
                  v-if="list_Form.coverPics[0]"
                  alt=""
                  style="width: 100%;height:100%"
                  :src="list_Form.coverPics[0]"
              />
            </div>
          </div>
          <div style="color: #a1a1a1">注:尺寸宽640*360，大小不超过500KB</div>
        </a-form-model-item>
        <a-form-model-item prop="beginImageDetail" label="背景图">
          <div style="display: flex;width: 450px;">
            <div class="uploadImg" >
              <input
                  style="width: 100px;height: 100px;opacity: 0;"
                  accept="image/*"
                  type="file"
                  @change="list_Form_back"
                  ref="list_Form_back_ref" />
            </div>
            <div
                class="upload_poster">
              <img
                  v-if="list_Form.homeCoverImage"
                  alt=""
                  style="width: 100%;height:100%"
                  :src="list_Form.homeCoverImage"
              />
            </div>
          </div>
          <div style="color: #a1a1a1">注:尺寸宽640，大小不超过1M</div>
        </a-form-model-item>
        <a-form-model-item label="直播开始时间">
              <a-date-picker
                  :allowClear="false"
                  v-model="list_Form.sortTime"
                  placeholder="请选择直播显示时间"
                  @change="startTimeChange"
                  format="YYYY-MM-DD HH:mm:ss"
                  :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
              />
        </a-form-model-item>
        <div
            :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
        >
          <a-button  @click="()=>{
            this.$refs.content_list_Form.resetFields();
            this.list_visible = false
            this.getLiveList(this.page_no, this.page_size, this.radioValue, this.titleData,this.year,this.domainCode)
          }">
            取消
          </a-button>
          <a-button style="margin-left: 10px" type="primary" @click="content_list_Ok">
            确认
          </a-button>
        </div>
      </a-form-model>
    </a-modal>
    </a-spin>
  </div>
</template>

<script>

import {
  CaseLive,
  get_per_OfThe_one_list,
  put_per_OfThe_one_list_ofNumber,
  putSwitch,
  upDown
} from "../../../service/MedicalConference";
import {update} from "../../../utils/update";
import moment from "moment";
import {getTemplateColorPic, getTemplates, postConvention} from "@/service/medicalConference_api";

const columns = [
  // {
  //   title: 'ID',
  //   dataIndex: 'id',
  //   key: 'id',
  //   width:'7%'
  // },
  {
    title: '会议名称',
    dataIndex: 'title',
    key: 'title',
    width:'35%',
    ellipsis: true,
  },
  {
    title: '会议地址',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: '会议日期',
    dataIndex: 'beginDate',
    key: 'beginDate',
    width:'10%',
  },
  {
    title: '是否发布',
    dataIndex: 'isPublished',
    key: 'isPublished',
    width:'10%',
    scopedSlots: { customRender: 'isPublished' },
  },
  {
    title: '功能设置',
    dataIndex: 'functionSet',
    key: 'functionSet',
    width:'10%',
    scopedSlots: { customRender: 'functionSet' },
  },
  {
    title: '创建时间',
    dataIndex: 'createdTime',
    key: 'createdTime',
    width:'15%',
  },
  {
    title: '操作',
    dataIndex: 'system',
    key: 'system',
    width:'10%',
    scopedSlots: { customRender: 'system' },
  },
];

export default {
  data(){
    return{
      add_visible:false,
      add_Form:{
        voverImg:'',
        mainColor:'#000000',
        coverPics: [],
        name:'',
        time:'',
        address:'',
        startTime:'',
        platform:undefined,
        template:undefined,
      },

      year:'',
      yearShowOne:false,
      size: 'default',
      promotion_visible:false,
      labelCol: { span: 4},
      wrapperCol: { span: 14 },
      radioValue:'',
      titleData:'',
      dataList:[],
      columns,
      page_no:1,
      page_size:10,
      total:null,
      spinning:false,
      promotion_form:{
        isBeginImage:0,
        beginImageDetail:[],
        beginImageTime:null,
        isBannerShow:0,
        bannerImage:'',
      },
      promotion_rules: {
        beginImageDetail: [{ required: true, message: '请上传图片', trigger: 'blur' }],
        beginImageTime: [{ required: true, message: '请输入时长', trigger: 'blur' }],
        bannerImage: [{ required: true, message: '请上传图片', trigger: 'blur' }],
      },
      listId:'',
      list_visible:false,
      list_Form:{
        title:"",
        address:"",
        beginDate:'',
        endDate:'',
        mainColor:'',
        coverPics:[],
        homeCoverImage:'',
        sortTime:'',
      },
      listTd:'',
      domainCode:'',
      template_list:[],
      templateListUrl:'',
      optionValue:'',
      yun_subordinate_select:[
        {
          id:1,
          name:'全部',
          domain_code:'',
          value: '',
        },
        {
          id:2,
          name:'医驰',
          domain_code:'yichimeeting',
          value:'https://yichimeeting-mp.yichimeeting.com'
        }, {
          id:3,
          name:'云ICU',
          domain_code:'yunicu',
          value:'https://yunicu-mp.yunicu.com'
        }, {
          id:4,
          name:'IE- Learning',
          domain_code:'ie-learning',
          value:'https://ielearning-mp.ie-learning.cn'
        },{
          id:5,
          name:'APnet',
          domain_code:'aphouse',
          value:'https://apnet-mp.aphouse.cn'
        },
      ],

    }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle',"会议管理")
    this.getLiveList()
  },
  methods:{
    //获取会议列表
    async getLiveList(page_no,page_size,isPublished,title,sortTime,domainCode) {
      this.spinning = true
      const response = await CaseLive(page_no,page_size,isPublished,title,sortTime,domainCode)
      if(response.code === 0){
        this.dataList = response.data.rows
        this.total = response.data.count
      }else {
        this.$message.warning("失败!",response.message)
      }
      this.spinning = false
    },
    restBtn(row){
      console.log(row)
      this.listTd = row.id
      this.list_visible = true
      // let form = this.list_Form
      this.list_Form = row
      // form.title = row.title
      // form.address = row.address
      // form.beginDate = row.beginDate
      // form.endDate = row.endDate

    },
    picher_onChange(value, dateString){
      this.list_Form.beginDate = dateString[0]
      this.list_Form.endDate = dateString[1]
    },
    content_list_Ok(){
      this.$refs.content_list_Form.validate(async valid => {
        if (valid) {
          let form = this.list_Form
          let data = {
            title:form.title,
            address:form.address,
            beginDate:form.beginDate,
            endDate:form.endDate,
            mainColor:form.mainColor,
            coverPics:form.coverPics,
            homeCoverImage:form.homeCoverImage,
            sortTime:form.sortTime,
          }
          const response = await putSwitch(this.listTd,data)
          if (response.code === 0) {
            this.$message.success("修改成功！")
            //关闭
            this.list_visible = false
            //  刷新
            await this.getLiveList(this.page_no, this.page_size, this.radioValue, this.titleData,this.year,this.domainCode)
          } else {
            this.$message.warning("失败!", response.message)
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    radioChange(){
      this.page_no=1
      this.getLiveList(this.page_no, this.page_size, this.radioValue, this.titleData,this.year,this.domainCode)
    },
    seachBtn(){
      if(this.year){
        this.year = moment(this.year).format('YYYY')
        this.year = this.year+'-01-01 00:00:00'
      }
      this.getLiveList(this.page_no, this.page_size, this.radioValue, this.titleData,this.year,this.domainCode)
    },
    resetBtn(){
      this.titleData = ''
      this.radioValue = ''
      this.page_no = 1
      this.year = ''
      this.domainCode = ''
      this.getLiveList(this.page_no, this.page_size, this.radioValue, this.titleData,this.year,this.domainCode)
    },
    paginationChange(page_no,page_size){
      this.page_no = page_no
      this.page_size = page_size
      this.getLiveList(this.page_no, this.page_size, this.radioValue, this.titleData,this.year,this.domainCode)
    },
    functionSet(row){
      this.$router.push({path:'/featureManagement',query:{id:row.id}})
    },
    //公用上移下移
    async upDown(convention_id,action) {
      const response = await upDown(convention_id,action)
      if (response.code === 0) {
        this.$message.success("操作成功！")
      //  刷新
        await this.getLiveList(this.page_no, this.page_size, this.radioValue, this.titleData,this.year,this.domainCode)
      } else {
        this.$message.warning("失败!", response.message)
      }
    },
    upBtn(id){
      let action = 'up'
      this.upDown(id,action)
    },
    downBtn(id){
      let action = 'down'
      this.upDown(id,action)
    },
    async switchBtn(id,isOpen) {
      let data = {
        "isPublished":isOpen,
      }
      const response = await putSwitch(id,data)
      if (response.code === 0) {
        this.spinning = true
        this.$message.success("操作成功！")
        //  刷新
        await this.getLiveList(this.page_no, this.page_size, this.radioValue, this.titleData,this.year,this.domainCode)
        this.spinning = false
      } else {
        this.$message.warning("失败!", response.message)
      }
    },
    //获取单个详情
    async getOneList(id) {
      const response = await get_per_OfThe_one_list(id)
      if (response.code === 0) {
        let form = this.promotion_form
        form.isBeginImage = response.data.isBeginImage
        if(response.data.beginImageDetail){
          form.beginImageDetail = response.data.beginImageDetail
        }
        form.beginImageTime = response.data.beginImageTime
        form.isBannerShow = response.data.isBannerShow
        form.bannerImage = response.data.bannerImage
      } else {
        this.$message.warning("失败!", response.message)
      }
    },
    promotion_Switch_btn(isOpen){
      this.promotion_form.isBeginImage = isOpen
    },
    openTwo_Switch_btn(isOpen){
      this.promotion_form.isBannerShow = isOpen
    },
    promotion_Btn(id){
      this.listId = id
      this.promotion_visible = true
      this.getOneList(id)
    },
    //修改公用
    async restGong(data) {
      const response = await put_per_OfThe_one_list_ofNumber(this.listId, data)
      if (response.code === 0) {
        this.$message.success("修改成功!")
      //  刷新
        await this.getOneList(this.listId)

      } else {
        this.$message.warning("失败!", response.message)
      }
    },
    promotion_Submit(){
      this.$refs.promotion_ruleForm_form_two.validate(valid => {
        if (valid) {
          let form = this.promotion_form
          let data = {
            "isBeginImage":form.isBeginImage,
            "beginImageTime": form.beginImageTime,
            "beginImageDetail": form.beginImageDetail,
            "isBannerShow":form.isBannerShow,
            "bannerImage":form.bannerImage
          }
          this.promotion_visible = false
          this.restGong(data)
          this.promotion_form.isBeginImage = null;
          this.promotion_form.beginImageDetail = [];
          this.promotion_form.beginImageTime = '';
          this.promotion_form.isBannerShow = 0;
          this.promotion_form.bannerImage = '';
        } else {
          this.$message.warning("有空~~")
          return false;
        }
      });
    },
    async promotion_upPosterCover() {
      let inputDOM = this.$refs.promotion_descImageUrl.files[0];
      //判断图片大小
      if (inputDOM.size < 1045504) {
        const image = await update(inputDOM)
        if (image.code === 0) {
          this.promotion_form.beginImageDetail[0] = image.data.url
          this.$message.success("上传成功")
        } else {
          this.$message.warning("上传失败")
        }
      } else {
        this.$message.warning("请上传小于1M的图片！")
      }
    },
    async promotion_upPosterCover_beginImage() {
      let inputDOM = this.$refs.promotion_descImageUrl_beginImage_two.files[0];
      //判断图片大小
      if (inputDOM.size < 1045504) {
        const image = await update(inputDOM)
        if (image.code === 0) {
          this.promotion_form.bannerImage = image.data.url
          this.$message.success("上传成功")
        } else {
          this.$message.warning("上传失败")
        }
      } else {
        this.$message.warning("请上传小于1M的图片！")
      }
    },
    colorAdd(e){
      this.add_Form.mainColor = e.target.value
    },
    colorRest(e){
      this.list_Form.mainColor = e.target.value
    },
    startTimeChange(data){
      this.list_Form.sortTime = moment(data).format('YYYY-MM-DD HH:mm:ss')
    },
    moment,
    async list_Form_upPosterCover() {
      let inputDOM = this.$refs.list_Form_upPosterCover_ref.files[0];
      //判断图片大小
      if (inputDOM.size < 512000) {
        const image = await update(inputDOM)
        if (image.code === 0) {
          this.list_Form.coverPics[0] = image.data.url
          this.$message.success("上传成功")
        } else {
          this.$message.warning("上传失败")
        }
      } else {
        this.$message.warning("请上传小于1M的图片！")
      }
    },
    async list_Form_back() {
      let inputDOM = this.$refs.list_Form_back_ref.files[0];
      //判断图片大小
      if (inputDOM.size < 1045504) {
        const image = await update(inputDOM)
        if (image.code === 0) {
          this.list_Form.homeCoverImage = image.data.url
          this.$message.success("上传成功")
        } else {
          this.$message.warning("上传失败")
        }
      } else {
        this.$message.warning("请上传小于1M的图片！")
      }
    },
    domainCodeChange(){
      if(this.year){
        this.year = moment(this.year).format('YYYY')
        this.year = this.year+'-01-01 00:00:00'
      }
      this.getLiveList(this.page_no, this.page_size, this.radioValue, this.titleData,this.year,this.domainCode)
    },
    // 弹出日历和关闭日历的回调
    openChangeOne(status) {
      if (status) {
        this.yearShowOne = true;
      }else {
        this.yearShowOne = false;
      }
    },
    // 得到年份选择器的值
    panelChangeOne(value) {
      this.yearShowOne = false;
      this.year = value;
      this.page_no = 1
      if(this.year){
        this.year = moment(this.year).format('YYYY')
        this.year = this.year+'-01-01 00:00:00'
      }
      this.getLiveList(this.page_no, this.page_size, this.radioValue, this.titleData,this.year,this.domainCode)
    },
    addTopBtn(){
      this.add_visible = true
    //  加载模板列表
      this.getTemList()
    },
    DateChange(date, dateString) {
      this.add_Form.time = dateString
    },
    StartChange(date, dateString){
      this.add_Form.startTime = date
    },
    selectMode(value){
      this.optionValue = value
    },
    templateChange(){
      console.log(this.add_Form.template)
    },
    async add_upPosterCover() {
      let inputDOM = this.$refs.add_upPosterCover_ref.files[0];
      //判断图片大小
      if (inputDOM.size < 512000) {
        const image = await update(inputDOM)
        if (image.code === 0) {
          this.add_Form.coverPics[0] = image.data.url
          this.$message.success("上传成功")
        } else {
          this.$message.warning("上传失败")
        }
      } else {
        this.$message.warning("请上传小于1M的图片！")
      }
    },
    async add_back() {
      let inputDOM = this.$refs.add_back_ref.files[0];
      //判断图片大小
      if (inputDOM.size < 512000) {
        const image = await update(inputDOM)
        if (image.code === 0) {
          this.add_Form.voverImg = image.data.url
          this.$message.success("上传成功")
        } else {
          this.$message.warning("上传失败")
        }
      } else {
        this.$message.warning("请上传小于1M的图片！")
      }
    },
    async add_content_list_Ok() {
      let form = this.add_Form
      const data = {
        cover_pics: form.coverPics,
        main_color: form.mainColor,
        home_cover_image: form.voverImg,
        temp_url: this.optionValue + this.templateListUrl,
        title: form.name,
        address: form.address,
        begin_date: moment(form.time[0]).format('YYYY-MM-DD'),
        end_date: moment(form.time[1]).format('YYYY-MM-DD'),
        domain_code: form.platform,
        // sort_time: moment(form.startTime).format('YYYY-MM-DD HH:mm:ss'),
        sort_time:new Date(form.startTime)
      }
      await this.post_convention(data)
      //  关闭对话框
      this.add_visible = false
      //  刷新列表
      await this.getLiveList()
    },
  //  获取列表 --- 模板列表
    async getTemList() {
      const response = await getTemplates()
      if(response.code === 0){
        this.template_list = response.data.hos
      }
    },
    // 获取列表 --- 模板列表中的url
    async getTemList_url(id) {
      const response = await getTemplateColorPic(id)
      if(response.code === 0){
        this.templateListUrl = response.data.url
      }
    },
    //新增医学会议列表
    async post_convention(data) {
      const response = await postConvention(data)
      if(response.code === 0){
        this.$message.success("新增成功~")
      }else {
        this.$message.warning(response.message)
      }
    },
    selectTem(id){
    //  通过选择的id获取url的后半截
      this.getTemList_url(id)
    },
  }
}
</script>

<style lang="scss" scoped>
.live-top{
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  border-bottom: 10px solid #f5f5f5;
  padding-top: 15px;
  .live-top-input{
    width: 25%;
    display: flex;
    height: 35px;
    line-height: 35px;
    padding-left: 30px;
    .input{
      width: 65%;
      margin-left: 5px;
    }
  }
}
.uploadImg{
  border: #DDDDDD 2px dashed;
  width: 120px;
  height: 120px;
  background-image: url("../../../assets/add.png");
  background-size:100% 100%;
  background-repeat:no-repeat;
  cursor: pointer;
}
.upload_poster{
  border: #DDDDDD 2px dashed;
  width:110px;
  min-height: 120px;
  margin-left: 20px;
  text-align: center;
}
.upload_poster_beginImage{
  border: #DDDDDD 2px dashed;
  width:192px;
  height: 52px;
  min-height: 120px;
  margin-left: 20px;
  text-align: center;
}
</style>