import {get, post,put,del} from './base'
import qs from "qs";

//首页模板地址列表
export function getSoftlinksList(pageNum){
    return get('/v3/hptemps',{pageNum})
}
export function getSoftlinks(id){
    return get('/v3/hptemp/' + id)
}
//新增首页模板地址
export function postSoftlinks(data){
    return post('/v3/hptemp' , data)
}
export function  putSoftlinks(id,data){
    return put('/v3/hptemp/' +id,data)
}
//模板是否开启
export function  putSwitchOpen(id,data){
    return put('/v3/hptempu/' +id, qs.stringify(data))
}
//删除首页模板
export function delSoftlinks(id){
    return del('/v3/hptemp/' + id)
}


//首页功能类型
//获取功能类型
export function getFunctionTypes(pageNum){
    return get('/v3/types',{pageNum})
}

export function getType(id){
    return get('/v3/type/' + id)
}
//新增功能类型
export function postFunctionTypes(data){
    return post('/v3/type' , data)
}
//是否开启
export function putTypesOpen(id,data){
    return put('/v3/typet/' +id,qs.stringify(data))
}
//修改
export function putFunctionTypes(id,data){
    return put('/v3/type/' +id,data)
}
//删除
export function delFunctionTypes(id){
    return del('/v3/type/' + id)
}


//首页功能管理
//获取功能列表
export  function  getFunctionList(pageNum){
    return get('/v3/cus',{pageNum})
}
export function getFunctionId(id){
    return get('/v3/cu/' +id)
}
export function postFunction(data){
    return post('/v3/cu',data)
}

export function putFunction(id,data){
    return put('/v3/cu/' + id ,data)
}
export function putFunctionOpen(id,data){
    return put('/v3/cu/open/' + id, qs.stringify(data))
}
export function delFunction(id){
    return del('/v3/cu/' +id)
}
export function geTypeList(){
    return get('/v3/types',{'pageNum':1,'pageSize':9999})
}



//首页模板管理

//获取模板列表
export function getTemplates(pageNum,name){
    return get('/v3/hometemps',{pageNum,name})
}
export function getTemplatrsId(id){
    return get('/v3/hometemp/' + id)
}
//新增模板
export function postTemplates(data){
    return post('/v3/hometemp',data)
}
//新增界面功能图标
export function getTemplateIcon(){
    return get('/v3/cus',{'pageNum':1,'pageSize':9999})
}
//新增界面选择模板
export function getTemplateTitle(){
    return get('/v3/hptemps',{'pageNum':1,'pageSize':9999})
}
//通过id获取背景图主色值和模板
export function getTemplateColorPic(id){
    return get('/v3/cvts/' + id)
}
export function postConvention(data){
    return post('/v3/convention',data)
}
export function putConvention(id,data){
    return put('/v3/hometemp/' +id,data)
}
//获取已选择功能列表
export function getFuncs(id){
    return get('/v3/funcs/' +id)
}
export function postFunc(sout,data){
    return post('/v3/func' +'?sout=' + sout,data)
}
export function delTemplate(id){
    return del('/v3/hometemp/' + id)
}
export function getTemplateFile(id){
    return get('/v3/hometemp/upload/' + id)
}
